/* General App Styling */
.App {
  text-align: center;
}

/* Header Section */
.App-header {
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  position: fixed;
  width: 100%;
  height: 100px;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 90px;
  height: 90px;
}

/* Navbar Section */
.navbar {
  text-align: right;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 25px;
  display: inline-flex; /* Default: horizontal row */
}

.navbar li {
  margin-left: 25px;
}

.navbar a {
  text-decoration: none;
  color: rgb(6, 0, 0);
  font-size: 1.1em;
  font-weight: bold;
  transition: color 0.3s;
}

.navbar a:hover {
  color: #a93126;
}

/* Hamburger Menu Styling */
.hamburger {
  display: none; /* Hidden by default */
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  cursor: pointer;
}

.hamburger .bar {
  height: 3px;
  width: 30px;
  background-color: #333;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.navbar.open ul {
  display: block; /* Show the menu when toggled */
}

.main {
  margin-top: 100px; /* Adjusted for header height */
}

/* CTA Section Styling */
.cta-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 20px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
  border-radius: 10px;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.cta-left, .cta-right {
  width: 48%;
  padding: 10px;
}

.cta-left h2 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 15px;
}

.cta-left p {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 20px;
}

.app-store-links {
  margin-top: 30px;
}

.store-icon {
  width: 160px;
  margin: 0 15px;
  transition: transform 0.3s;
}

.store-icon:hover {
  transform: scale(1.1);
}

.cta-right {
  width: 45%;
}

.app-screenshot {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

/* Features Section Styling */
.features-section {
  background-color: #f8f8f8;
  padding: 60px 20px;
}

.features-section h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #333;
}

.features-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  background-color: white;
  width: 22%;
  padding: 20px;
  text-align: center;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 20px;
  border-radius: 10px;
}

.card h3 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #333;
}

.card p {
  font-size: 1em;
  color: #555;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
}

/* Contact Section Styling */
.contact-section {
  background-color: #fff;
  padding: 40px 10px;
}

.contact-section h2 {
  font-size: 2.5em;
  color: #333;
}

.contact-section p {
  font-size: 1em;
  color: #666;
}

.contact-section a {
  color: #f76c6c;
  text-decoration: none;
  font-weight: bold;
}

.contact-section a:hover {
  color: #ff6f61;
  text-decoration: underline;
}

.social {
  display: flex;
}

.socialMedia {
  height: 30px;
  width: 30px;
  margin-left: 15px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .logo-container {
    display: flex;
  }
  
  .logo {
    margin-right: 20px;
  }

  .navbar ul {
    display: none;
    position: absolute;
    top: 100px;
    right: 0;
    background-color: white;
    width: 100%;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .hamburger {
    display: flex;
    margin-right: 30px;
  }

  .navbar.open ul {
    display: block;
  }

  .navbar li {
    margin: 15px 0 0px 35px;
    text-align: left;

  }

  .cta-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .cta-left, .cta-right {
    width: 100%;
    padding: 10px;
  }

  .cta-left h2 {
    font-size: 2em;
  }

  .cta-left p {
    font-size: 1em;
  }

  .app-store-links {
    margin-top: 20px;
  }

  .cta-right {
    margin-top: 20px;
  }

  .store-icon {
    width: 140px;
    margin: 0 10px;
  }

  .features-cards {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 90%;
    margin-bottom: 20px;
  }
}
