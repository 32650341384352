/* FAQPage.css */
.faq-page {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .faq-page h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
  }
  
  .faq-list {
    border-top: 1px solid #ddd;
  }
  
  .faq-item {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .faq-item.open {
    background-color: #f9f9f9;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 1.1em;
    color: #333;
    padding: 10px;
  }
  
  .faq-icon {
    font-size: 1.5em;
    color: #007bff;
    transition: transform 0.3s ease;
  }
  
  .faq-item.open .faq-icon {
    transform: rotate(180deg);
  }
  
  .faq-answer {
    margin-top: 10px;
    font-size: 1em;
    color: #555;
    padding-left: 10px;
    line-height: 1.5;
  }
  